import Home from 'pages/Home';
import BarraNavegacao from 'components/BarraNavegacao';
import WhatsButton from 'components/WhatsButton';
import servicos from './pages/Catalogo/servicos'

import Rodape from 'components/Rodape';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import NaoEncontrada from 'pages/NaoEncontrada';
import Catalogo from 'pages/Catalogo';
import Bio from 'components/Bio';

export default function AppRouter() {
  
  return (
    <main>
       <Router>
        <WhatsButton />
        <BarraNavegacao />
        <Routes>
          <Route index path='/' element={<Home />}/>
          <Route index path='/deisi' element={<Bio />}/>
          {/* CONSULTA A DOMICILIO  */}
          <Route index 
            path={`/${servicos[0].chave}`}
            element={
              <Catalogo
                titulo={servicos[0].titulo}
                nome={servicos[0].nome}
                chave={servicos[0].chave}
                imagem={servicos[0].imagem}
                texto1={servicos[0].texto1}
                texto2={servicos[0].texto2}
                texto3={servicos[0].texto3}
                texto4={servicos[0].texto4}                
          />}/>
          {/* VACINAS */}
          <Route index 
            path={`/${servicos[1].chave}`}
            element={
              <Catalogo
                titulo={servicos[1].titulo}
                nome={servicos[1].nome}
                chave={servicos[1].chave}
                imagem={servicos[1].imagem}
                texto1={servicos[1].texto1}
                texto2={servicos[1].texto2}
                texto3={servicos[1].texto3}
                texto4={servicos[1].texto4}                      
          />}/>
          {/* CHECK-UPS   */}
          <Route index 
            path={`/${servicos[2].chave}`}
            element={
              <Catalogo
                titulo={servicos[2].titulo}
                nome={servicos[2].nome}
                chave={servicos[2].chave}
                imagem={servicos[2].imagem}
                texto1={servicos[2].texto1}
                texto2={servicos[2].texto2}
                texto3={servicos[2].texto3}
                texto4={servicos[2].texto4}               
          />}/>,
          {/* MICRO-CHIP */}
          <Route index 
            path={`/${servicos[3].chave}`}
            element={
              <Catalogo
                titulo={servicos[3].titulo}
                nome={servicos[3].nome}
                chave={servicos[3].chave}
                imagem={servicos[3].imagem}
                texto1={servicos[3].texto1}
                texto2={servicos[3].texto2}
                texto3={servicos[3].texto3}
                texto4={servicos[3].texto4}              
          />}/>,
          {/* EXAMES */}
          <Route index 
            path={`/${servicos[4].chave}`}
            element={
              <Catalogo
                titulo={servicos[4].titulo}
                nome={servicos[4].nome}
                chave={servicos[4].chave}
                imagem={servicos[4].imagem}
                texto1={servicos[4].texto1}
                texto2={servicos[4].texto2}
                texto3={servicos[4].texto3}
                texto4={servicos[4].texto4}                       
          />}/>,
          {/* MEDICAMENTOS INJETÁVEIS */}          
          <Route index 
            path={`/${servicos[5].chave}`}
            element={
              <Catalogo
                titulo={servicos[5].titulo}
                nome={servicos[5].nome}
                chave={servicos[5].chave}
                imagem={servicos[5].imagem}
                texto1={servicos[5].texto1}
                texto2={servicos[5].texto2}
                texto3={servicos[5].texto3}
                texto4={servicos[5].texto4}               
          />}/>,
          {/* FLUIDOTERAPIA */}          
          <Route index 
            path={`/${servicos[6].chave}`}
            element={
              <Catalogo
                titulo={servicos[6].titulo}
                nome={servicos[6].nome}
                chave={servicos[6].chave}
                imagem={servicos[6].imagem}
                texto1={servicos[6].texto1}
                texto2={servicos[6].texto2}
                texto3={servicos[6].texto3}
                texto4={servicos[6].texto4}                   
          />}/>,
          {/* CURATIVO */}          
          <Route index 
            path={`/${servicos[7].chave}`}
            element={
              <Catalogo
                titulo={servicos[7].titulo}
                nome={servicos[7].nome}
                chave={servicos[7].chave}
                imagem={servicos[7].imagem}
                texto1={servicos[7].texto1}
                texto2={servicos[7].texto2}
                texto3={servicos[7].texto3}
                texto4={servicos[7].texto4}              
          />}/>,
          {/* CUIDADOS PALIATIVOS*/}          
          <Route index 
            path={`/${servicos[8].chave}`}
            element={
              <Catalogo
                titulo={servicos[8].titulo}
                nome={servicos[8].nome}
                chave={servicos[8].chave}
                imagem={servicos[8].imagem}
                texto1={servicos[8].texto1}
                texto2={servicos[8].texto2}
                texto3={servicos[8].texto3} 
                texto4={servicos[8].texto4}             
          />}/>,
          <Route path='*' element={<NaoEncontrada />} />
        </Routes>
        <Rodape />
      </Router>
    </main>
  );
}