import Banner from './Carrossel';
import Bio from '../../components/Bio';
import Sobre from '../../components/Sobre';
import Atendimento from '../../components/Atendimento'

export default function Home () {

    return( <>
                <Banner />
                <Sobre />
                <Bio />
                <Atendimento />
            </>)
}