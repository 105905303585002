import{SiLinkedin, SiWhatsapp, SiInstagram } from 'react-icons/si'
import style from './Rodape.module.scss';
import logo from './logotipo.png'

export default function Rodape () {
    
    return(
    <footer className ={style.rodape}>
     
         <br></br>
        <div className={style.rodape__icons}>        
        <a href='https://www.instagram.com/vetpassofundo' target='blank'>
            <span><SiInstagram /></span>
        </a> 
            <a href='/redirect.html' target='blank'>
            <span><SiWhatsapp /></span>
        </a>        
        <a href={'https://www.linkedin.com/in/deisi-santos-5152a193/'} target='blank'>
            <span><SiLinkedin /></span>
        </a>     
        </div>
       
        <p className={style.copyright}>2024 - Todos os Direitos reservados</p>        
        <img width={'50px'} src={logo}/>
        <p className={style.copyright}>Deisi Santos CRMV-RS 23005</p>
        <p className={style.mail}>deisisantosvet@hotmail.com</p>
    </footer>  
    )
}
