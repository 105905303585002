import style from 'components/BarraNavegacao/BarraNavegacao.module.scss'
import {Link} from 'react-router-dom'
import { useState } from 'react'
import Servicos from '../../pages/Catalogo/servicos'
import { SiWhatsapp } from "react-icons/si";
import logo from '../Rodape/logotipo.png'


export default function BarraNavegacao () {

    const [checked, setChecked] = useState(false);

    return(<>
    <nav className={style.barraNavegacao}>
      
      <img className={style.logo} height='40px' src={logo}/>
      
      <div><p className={style.marca}>Veterinária Passo Fundo</p>
        <a href='/redirect.html' target='blank'>   
         <p className={style.subtitulo}>Deisi Santos - <SiWhatsapp size={12}/>(54)99164-8912</p>
        </a>
      </div>
            <label>
        <input checked={checked} type="checkbox" onScroll={() => (setChecked(!checked))} onClick={() => (setChecked(!checked))}/>
        <span className={style.hamburguer__menu}> <span className={style.hamburger__botao}></span> </span>
        <ul className={style.hamburguer__item}>
        <p className={style.marca}>Veterinária Passo Fundo</p>
          <div className={style.hamburguer__quadro}>            
            <li className={style.hamburguer__opcoes} onClick={() => (setChecked(false))}><Link to={'/'}><h4>Home</h4></Link></li>
            <li className={style.hamburguer__opcoes} onClick={() => (setChecked(false))}><Link to={'/deisi'}><h4>Médica Veterinária</h4></Link></li>
            {Servicos.map((item) => <li className={style.hamburguer__opcoes} onClick={() => (setChecked(false))}><Link to={`./${item.chave}`}><h4>{item.nome}</h4></Link></li>)
            }            
          </div>
        </ul>
        </label>
      </nav>
      <div className={style.espaco}><heading>Atendimento domiciliar para cães e gatos</heading></div>
      </>)
}