import vacina from './vacina.jpg'
import check from './check.jpg'
import chip from './chip.jpg'
import cuidado from './cuidados.jpg'
import exame from './exame.jpg'
import consulta from './consulta.jpg'
import curativo from './curativo.jpg'
import fluidoterapia from './fluidoterapia.jpg'

const Servicos = [ {
  //CONSULTA A DOMICILIO 
  imagem: <img src={ consulta } />,
  nome: "Consulta a domicílio",
  chave: "consulta",
  titulo: "Seu pet não está bem? Nós vamos até você",
  texto1: "Conforto e menos stress: as vezes é estressante para o pet chegar em um ambiente de clínica ou hospital com muitos cheiros, principalmente se não estiver habituado a essa rotina. Com a consulta a domicílio, o médico veterinário consegue avaliar o paciente com mais zelo e atenção e observar se existe algum comportamento diferente que exija mais atenção.",
  texto2: "Comodidade aos tutores: Nossa sociedade cada vez tem menos tempo para deslocamento e esperas prolongadas que ocorre algumas vezes em clínicas devido emergências ou alguma situação que possa levar o atraso entre uma consulta e outra. Com o agendamento domiciliar,o veterinário vai até você. Acontece também, casos que há mais de um animal na casa, onde seria mais difícil a locomoção e cuidados.",
  texto3: "Ao realizar a consulta em casa, o veterinário pode observar o ambiente que o pet vive e revelar fatores que  influenciam na saúde do mesmo. Pensando também na prevenção de patologias que podem ocorrer com o passar dos anos.",
  texto4: "Durante a consulta, o seu pet é o único a receber toda a atenção do veterinário, sem distrações típicas de uma clínica. Existe uma comunicação mais direta e personalizada para sanar suas duvidas e preocupações."
}, {
  //VACINAS  
  imagem: <img src={ vacina } />,
  nome: "Vacinas",
  chave: "vacina",
  titulo: "Vacine seu Pet sem sair de casa",
  texto1: "Vacinas para cães e gatos no conforto da sua casa com vacinas importadas. Esquemas de vacinação completos para garantir a saúde e bem estar do animal de estimação.",
  texto2: "Atuam no fortalecimento do sistema imunológico protegendo contra doenças potencialmente graves e até fatais. Além de proteger diretamente os animais, a vacina contribui para a saúde pública, reduzindo agentes patológicos no ambiente. Outra vantagem é a redução de custos em tratamentos veterinários, animais vacinados tem menor probabilidade de adoecer gravemente principalmente nos primeiros meses de vida, onde a imunidade ainda está sendo desenvolvida. ",
  texto3: "Você Sabia? Existem doenças que podem ser transmitidas para humanos, são chamadas de zoonose, um exemplo é a giardiase, uma doença que pode causar diarreia e outros sintomas. Elas podem ser prevenidas através de vacinas.",
  texto4: "Manter a vacinação em dia é uma responsabilidade que todo o tutor deve assumir."
}, {
  //CHECK-UPS  
  imagem: <img src={ check } />,
  nome: "Check-ups",
  chave: "check",
  titulo: "Check-up preventivo",
  texto1: "Com o check-up anual podemos avaliar a saúde do seu pet e prevenir doenças.",
  texto2: "Exames preventivos auxiliam no acompanhamento da evolução da saúde, onde conseguimos mensurar ao longo dos anos se houve alguma alteração.",
  texto3: "Podemos fazer a prevenção através de medicamentos, troca de ração ou até cuidados diários para modificar algum comportamento nocivo.",
}, {
  //MICRO-CHIP  
  imagem: <img src={ chip } />,
  nome: "Micro-chipagem",
  chave: "chip",
  titulo: "Uma picadinha e seu pet poderá ser sempre identificado.",
  texto1: "Identificação permanente: o microchip fornece uma forma permanente de identificar o pet. Diferente de colares e plaquinhas que podem ser removidos ou se perderem, o microchip fica sob a pele do pet e contem um número único de identificação que pode ser lido com um scanner especial.",
  texto2: "Facilidade na localização de animais perdidos: se o seu pet foi encontrado, o microchip permite que clínicas veterinárias, abrigos ou outras instituições identifiquem rapidamente o tutor, aumentando assim as chances do reencontro entre pet e tutor.",
  texto3: "Requisito legas e de viagem: em muitos lugares o microchip é exigido por lei e para viagens internacionais, especialmente para países com regulamentos rígidos de quarentena, o microchip é necessário como prova de identificação.",
}, {
  //COLETA DE EXAMES 
  imagem: <img src={ exame } />,
  nome: "Coleta de Exames",
  chave: "exames",
  titulo: "Coleta de exames sem stress.",
  texto1: "Amostras de sangue, urina, fezes ou pele podem ser coletadas pelo veterinário durante uma visita domiciliar para posterior análise em laboratório. ",
  texto2: "Com esses exames podemos diagnosticar diversas condições sem a necessidade de transportar o paciente, evitando assim o stress que pode alterar os exames.",
  texto3: "Você sabia? O stress pode causar um leucograma de stress e com isso pode ser interpretado de forma errônea e atrapalhar o tratamento do seu pet.",
}, {
  //MEDICAMENTOS INJETÁVEIS
  imagem: <img src={ exame } />,
  nome: "Medicamentos Injetáveis",
  chave: "injetaveis",
  titulo: "Medicamentos injetáveis.",
  texto1: "As medicações injetáveis oferecem diversas vantagens em pacientes que apresentam náuseas e vômitos quando utilizam medicamentos por via oral. Primeiramente, a administração injetável garante que o medicamento seja absorvido diretamente na corrente sanguínea, evitando o trato gastrointestinal. Isso é particularmente importante em casos de pacientes com enjoos, já que a ingestão de medicamentos orais pode exacerbar os sintomas de náusea e até mesmo resultar na expulsão do medicamento antes que ele seja devidamente absorvido.",
  texto2: "Além disso, a via injetável permite uma ação mais rápida e eficaz, o que é crucial em situações onde o alívio rápido dos sintomas é necessário. Pacientes que estão vomitando não conseguem manter os medicamentos orais no estômago por tempo suficiente para que sejam absorvidos adequadamente, comprometendo o tratamento. A administração injetável, por outro lado, contorna esse problema, garantindo que o paciente receba a dose completa do medicamento.",

}, {
  //FLUIDOTERAPIA
  imagem: <img src={ fluidoterapia } />,
  nome: "Fluidoterapia",
  chave: "fluidoterapia",
  titulo: "Fluidoterapia em casa.",
  texto1: "Principalmente para nossos pacientes felinos com doença renal cronica (DRC) é importante controlar os desequilíbrios eletrolíticos e desidratação que são consequências frequentes dessa doença.",
  texto2: "A capacidade renais de filtração e concentração de urina é comprometida, levando a perda excessiva de água e eletrólitos e a desidratação crônica, com a administração de fluido por via subcutânea ajuda a repor essas perdas e manter o paciente com a hidratação adequada, melhorando o fluxo renal e melhora sintomas associados a DRC como a letargia, inapetência, proporcionando uma melhor qualidade de vida ao paciente.",
  texto3: "Além de corrigir a desidratação, a fluidoterapia também ajuda a diluir toxinas urêmicas que se acumulam no sangue devido à função renal comprometida. Isso pode reduzir os efeitos tóxicos dessas substâncias no organismo e aliviar sintomas como náusea e mal-estar. A administração regular de fluidos também pode auxiliar na manutenção da função renal remanescente, retardando a progressão da doença.."
}, {
  //CURATIVOS
  imagem: <img src={ curativo } />,
  nome: "Troca de curativos",
  chave: "curativo",
  titulo: "Troca de curativos em casa.",
  texto1: "Os curativos realizados por veterinários em domicílio são uma alternativa valiosa para o cuidado de animais que necessitam de atenção especial, oferecendo uma série de benefícios tanto para os pets quanto para seus tutores. Esse serviço se destaca por proporcionar um ambiente menos estressante para o animal, o que é particularmente importante em casos de feridas ou lesões que requerem cuidados contínuos e delicados.",
  texto2: "Uma das principais vantagens dos curativos realizados em casa é a conveniência para o tutor e o conforto para o animal. Muitos pets podem se sentir ansiosos ou estressados ao serem transportados para uma clínica veterinária. Em um ambiente familiar, o animal está mais relaxado, o que facilita o trabalho do veterinário e pode contribuir para uma recuperação mais rápida.",
  texto3: "Os curativos realizados em domicílio também são uma solução prática para tutores com dificuldade de locomoção, horários limitados ou que possuem múltiplos animais de estimação. O veterinário pode oferecer instruções detalhadas sobre como manter o curativo limpo e protegido entre as visitas, além de ajustar o tratamento conforme a evolução da ferida",
  texto4: "Em termos de técnica, o veterinário que realiza curativos em domicílio é capaz de aplicar uma variedade de métodos, desde os mais simples, como curativos adesivos, até os mais complexos, que podem envolver a aplicação de pomadas medicinais, bandagens especializadas."
}, {
  //CUIDADOS PALIATIVOS
  imagem: <img src={ cuidado } />,
  nome: "Cuidados paliativos",
  chave: "cuidado",
  titulo: "Cuidados paliativos para seu amigo.",
  texto1: "Algumas vezes por doenças crônicas ou terminais, o que mais precisamos é oferecer cuidados paliativos para proporcionar conforto e alívio dos sintomas.",
  texto2: "Esse serviço inclui o manejo da dor, ajustes das medicações e orientações para os tutores de como manter o bem-estar do pet durante essa fase.",
  texto3: "Em situações onde a qualidade de vida do animal está comprometido, após uma discussão cuidadosa com o tutor, o veterinário pode realizar a eutanásia de uma forma humanitária e tranquila no ambiente familiar, oferecendo um momento de despedida menos traumático para o tutor e para o pet."
},
]

export default Servicos
