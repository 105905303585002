import style from './Carrossel.module.scss';
import Carousel from 'react-bootstrap/Carousel'
import { CarouselItem } from 'react-bootstrap';
import Servicos from '../../Catalogo/servicos'
import logoBranco from './logo_small.png'

export default function Carrossel () {


return(
<div className={style.banner}>
<Carousel>

{Servicos.map((item) => {return(
  <CarouselItem key={item.chave}>
      <a href={`/${item.chave}`}>
        {item.imagem}
      <Carousel.Caption>        
        <heading><h1>{item.titulo}<npm img className={style.logo} src={logoBranco}/>  </h1></heading>
        <br></br>
        <p>Clique para saber mais</p>
              
      </Carousel.Caption></a>   
  </CarouselItem>
)})}
</Carousel>
</div>
            )
}