import style from './Catalogo.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import Servicos from './servicos';

function Catalogo(servico) {
    const navigate = useNavigate();

    return( <>      
    <section className={style.servicos}>
                <div className={style.servicos__titulo}>
                <h4>{servico.titulo}</h4>
                <div className={style.servicos__imagem}>
                {servico.imagem}
                </div>
                <p className={style.servicos__texto}>{servico.texto1}</p>               
                <p className={style.servicos__texto}>{servico.texto2}</p>
                <p className={style.servicos__texto}>{servico.texto3}</p>
                <p className={style.servicos__texto}>{servico.texto4}</p>
                <a href='/redirect.html' target='blank'> 
                <button className={style.servicos__botao}>Agende uma consulta.</button>
                </a>
                </div>
                
                
    </section>
    <section className={style.servicos}>
        <div className={style.servicos__titulo}>Veja mais serviços</div>
        <div className={style.servicos__badges}>
            {Servicos.map((item) =>
                <Link to={`/${item.chave}`}>
                    <div className={style.servicos__badge}>
                        {item.nome}
                    </div>
                </Link>)}</div>
    </section>
    
 </>)
}

export default Catalogo