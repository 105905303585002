import ReactDOM from 'react-dom/client';
import './index.css';
import AppRouter from 'routes';
import { Analytics } from "@vercel/analytics/react"

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement
);
root.render( <>
  <Analytics />
  <AppRouter />
</>

);

