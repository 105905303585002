import style from './Bio.module.scss';
import Content from 'components/Content';
import DEISI_BAIXA from './DEISI_BAIXA.JPG'

export default function Bio () {

    return(<>
    
    <Content title={'Médica Veterinária'} >
     <>     <div className={style.profissional}>
                <div className={style.profissional__box1}>
                <h1>Deisi Santos</h1>
                    <img className={style.profissional__fotoMini} src={DEISI_BAIXA}></img>
                <p>CRMV-RS 23005</p>   
                </div>
                <div className={style.profissional__box2}>
                    <ul className={style.profissional__box2}>
                    <li>✓ Mais de 14 anos de experiência no atendimento de pequenos animais no Brasil e em Portugal;</li>     
                    <li>✓ Médica Veterinária graduada pela UPF;</li>
                    <li>✓ Pós-Graduada em clínica e cirurgia de pequenos animais;</li>
                    <li>✓ Especialização internacional em cirurgias e cuidados intensivos;</li>
                    <li>✓ Experiência no atendimento de várias especialidades em hospitais veterinários;</li>  
                    </ul>
                </div>
            </div>        
          </>
     </Content>                
</>    
    )
}