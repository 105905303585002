import style from './Atendimento.module.scss';
import Content from 'components/Content';
import {SiWhatsapp} from 'react-icons/si'
import Servicos from '../../pages/Catalogo/servicos'

export default function Atendimento () {

    return(<>
    <Content title={'Passo Fundo e Região'} >
        <div className={style.atendimento}>
            <div className={style.atendimento__box1}>
                <h2 className={style.content__description}>Agende seu horário:</h2>
                <div className={style.atendimento__button}><a href='/redirect.html' target='blank'><button className={style.atendimento__button}><SiWhatsapp color='inherit'/> (54) 99164-8912</button></a></div>
            </div>
            <div className={style.atendimento__box2}>
                {Servicos.map((item) => <a href={`./${item.chave}`} target='_blank'><span className={style.atendimento__badge}>{item.nome}</span></a>)}               
            </div>

        </div>
        
 
        

    </Content>
    </>
    )
}