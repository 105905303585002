import style from './Sobre.module.scss';
import Content from 'components/Content';

export default function Sobre () {

    return(<>    
    <Content title={'Veterinária a domicílio'} >
     <>     <div className={style.profissional}>                 
                
                    
                    <h4>Atendimento veterinário a domicílio para cães e gatos.</h4>
                        
                    <h4>Mais comodidade para você. Menos stress para o seu pet. </h4>
                        
                    <h4>Tenha na comodidade do seu lar uma veterinária com mais de 14 anos de experiência.</h4>
                    <h4>Realizamos vários procedimentos a domicílio, incluindo radiografias e ultrassonografias.</h4>
                    <h4>Para procedimentos mais complexos, temos espaços parceiros para atuar.</h4>
                    
            </div>        
          </>
     </Content>                
</>    
    )
}